<template>
  <BCard
    body-class="rounded-lg p-0 bg-image"
    style="box-shadow: 0px 0px 15px 0px #00000026 !important"
  >
    <div class="flex flex-col gap-5 p-1">
      <div class="flex items-center justify-between">
        <strong class="text-black text-lg">Voucher Iklan</strong>
        <div class="flex items-center gap-1">
          <img
            src="https://storage.googleapis.com/komerce/assets/LP-Komerce/facebook-ftr.svg"
            alt="Komerce"
          >
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/Google.svg"
            alt="Komerce"
          >
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/TikTok.svg"
            alt="Komerce"
          >
        </div>
      </div>
      <strong class="text-primary font-semibold text-3xl">
        {{ IDR(voucher.amount) }}
      </strong>
      <div class="flex items-center justify-end gap-1">
        <span>Harga:</span>
        <img
          class="w-5"
          src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
          alt="kompoint"
        >
        <span class="text-black font-medium">{{ IDRWithoutLbl(voucher.kompoint_fee) }}</span>
      </div>
    </div>
    <div
      v-if="source === 'list'"
      class="text-center rounded-b-lg p-[5px]"
      :class="isActiveRedeem ? 'bg-primary cursor-pointer' : 'bg-[#E2E2E2] cursor-default'"
      @click="isActiveRedeem && $emit('redeemVoucher', voucher) "
    >
      <strong class="text-white text-lg">Redeem Voucher</strong>
    </div>
  </BCard>
</template>

<script>
import { IDR, IDRWithoutLbl } from '@/libs/currency'
import { mapState } from 'vuex'

export default {
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    kompoints: {
      type: Object,
      required: true,
    },
    source: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      IDR,
      IDRWithoutLbl,
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
    isActiveRedeem() {
      return this.profile.is_komcards === 1 && this.kompoints.balance >= this.voucher.kompoint_fee
    },
  },
}
</script>
<style scoped>
.bg-image {
  background-image: url('https://storage.googleapis.com/komerce/assets/elements/element-bg-card.svg');
  background-repeat: no-repeat;
  background-position: right -70px bottom -20px;
  background-size: 200px;
}
</style>
