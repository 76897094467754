<template>
  <BCard>
    <div class="flex flex-col gap-3">
      <div class="flex flex-row items-center justify-between">
        <div class="flex">
          <BButton
            variant="primary"
            size="sm"
            class="mr-1 rounded-lg p-0"
            @click="$router.go(-1)"
          >
            <feather-icon
              size="2x"
              icon="ChevronLeftIcon"
            />
          </BButton>
          <strong class="text-black text-2xl">Bayar</strong>

        </div>
        <div class="text-black p-[5px] rounded-md flex items-center gap-2 bg-[#fef2cd]">
          <span>Kompoints:</span>
          <img
            class="w-6"
            src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
            alt="kompoint"
          >
          <strong class="text-black">{{ IDRWithoutLbl(kompoints.balance) }}</strong>
        </div>
      </div>
      <BRow class="p-1">
        <BCol
          cols="12"
          class="border rounded-lg p-1"
        >
          <BCard body-class="p-0">
            <div class="flex items-center gap-3">
              <img
                src="https://storage.googleapis.com/komerce/assets/LP-Komerce/komcards.svg"
                alt="Komerce"
              >
              <div class="text-black font-medium text-lg">
                Komcards
              </div>
            </div>
            <div class="text-black">
              Gunakan Kompoints-mu sekarang untuk membeli voucher iklan agar
              bisa menghemat biaya bayar iklan!
            </div>
          </BCard>
          <BRow v-if="profile.is_komcards !== 1">
            <BCol cols="12">
              <BAlert
                show
                variant="warning"
                class="p-2"
              >
                <div class="flex flex-row items-center justify-between gap-2">
                  <div>
                    <div class="flex items-center gap-3 mb-[10px]">
                      <img
                        src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
                        alt="Komerce"
                      >
                      <h4 class="text-black font-semibold m-0">
                        Kamu belum terdaftar sebagai partner Komcards
                      </h4>
                    </div>
                    <BCardText class="text-[#333333]">
                      Yuk daftar Komcards sekarang juga dan dapatkan cashback untuk membeli voucher!
                    </BCardText>
                  </div>
                  <a
                    href="https://komcards.id/"
                    target="_blank"
                  >
                    <BButton
                      size="sm"
                      variant="outline-warning"
                    >
                      Pelajari lebih lanjut
                    </BButton>
                  </a>
                </div>
              </BAlert>
            </BCol>
          </BRow>
          <BOverlay
            :show="loading.vouchers"
            spinner-variant="primary"
            variant="light"
            blur="0"
            opacity=".5"
            rounded="sm"
          >
            <BRow>
              <BCol
                v-for="item in vouchers"
                :key="item.id"
                cols="12"
                sm="12"
                md="6"
                lg="4"
              >
                <Voucher
                  :voucher="item"
                  :source="'list'"
                  :kompoints="kompoints"
                  @redeemVoucher="redeemVoucher"
                />
              </BCol>

            </BRow>
          </BOverlay>
        </BCol>
      </BRow>
    </div>
    <ModalRedeemVue
      :kompoints="kompoints"
      :voucher="voucher"
      @getVoucherList="getVoucherList"
      @getKompoints="getKompoints"
    />
  </BCard>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import { IDR, IDRWithoutLbl } from '@/libs/currency'
import { mapState } from 'vuex'
import ModalRedeemVue from './ModalRedeem.vue'
import Voucher from './Voucher.vue'

export default {
  components: { ModalRedeemVue, Voucher },
  data() {
    return {
      IDR,
      IDRWithoutLbl,
      loading: {
        kompoints: false,
        vouchers: false,
      },
      kompoints: {},
      vouchers: [],
      voucher: {},
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.getKompoints()
    this.getVoucherList()
  },
  methods: {
    async getKompoints() {
      this.loading.kompoints = true
      const url = '/auth/api/v1/user/kompoints'
      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res.data
          this.kompoints = data
          this.loading.kompoints = false
        })
        .catch(() => {
          this.loading.kompoints = false
          this.$toast_error({ message: 'Gagal mendapatkan kompoints' })
        })
    },
    async getVoucherList() {
      const url = '/komcards/api/v1/voucher/list'
      await newAxiosIns
        .get(url)
        .then(res => {
          const { data } = res
          this.vouchers = data.data
          this.loading.vouchers = false
        })
        .catch(() => {
          this.loading.vouchers = false
          this.$toast_error({ message: 'Gagal mendapatkan voucher' })
        })
    },
    redeemVoucher(value) {
      if (this.kompoints.balance < value.kompoint_fee) return this.$toast_error({ message: 'Kompoints tidak cukup' })
      this.voucher = value
      return this.$bvModal.show('modal-redeem')
    },
  },
}
</script>
<style lang="css" scoped>
.alert-warning{
  background: #F8ECC8 !important;
}
</style>
